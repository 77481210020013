#reach-us {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

#socials-wrapper {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
}

#socials-links {
    display: flex;
    justify-content: space-evenly;
}

#instagram {
    color: #ff306c;
}

#github {
    color: white;
}

@media only screen and (min-width: 500px) {
    #footer {
        height: 30%;
        width: 100%;
        background: #181818;
        color: aliceblue;
        display: flex;
        justify-content: space-around;
    }
}

@media only screen and (max-width: 499px) {
    #footer {
        height: 30%;
        width: 100%;
        background: #181818;
        color: aliceblue;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
}

