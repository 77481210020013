#navbar {
    height: 15%;
    width: 100%;
    max-height: 75px;
    position: fixed;
    background-color: transparent;
    transition: background-color 0.3s ease 0ms;
}

#navbar-content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;

}

a {
    color: red;
}

/* Reset cursor to pointer finger thing when hovering over navbar link */
a:hover {
    cursor: pointer;
}

#broncosec-logo {
    display: flex;
    flex-direction: row;
}

.transparent {
    background-color: transparent;
}

.opaque {
    background-color: white !important;
}

/* navbar link that's currently active */
.active {
    text-decoration: underline;
    text-decoration-color: red;
}

#navbar-content-responsive {
    display: flex;
    justify-content: space-between;
    padding: 0 5%;
}
