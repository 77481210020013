.event-type {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: center;
}

.event-img-wrapper {
    width: 80%;
    height: 100%;
}

.event-type-img {
    border-radius: 50%;
    /*width: 75%;*/
    height: 100%;
    max-height: 200px;
    width: 100%;
}
