.quick-link {
    height: 100%;
    width: 50%;
    text-decoration: none;
    color: black !important;
}

.quick-link-div {
    height: 100%;
    width: 100%;
    border-radius: 1em;
    overflow: hidden;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    transition: background 0.2s ease 0ms;
    padding-top: 10%;
    margin: 10% 0;
    align-self: center;
}

.quick-link-img {
    width: 25%;
}

.quick-link-div:hover {
    background-color: rgba(150,150,150,0.2);
}

.quick-link-col {
    margin: 3% 0;
}
