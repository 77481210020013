.board-member-image {
    border-radius: 50%;
    max-width: 60%;
}

.board-member-minimized {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    transition: background 0.2s ease 0ms;
    border-radius: 1em;
    padding: 5% 0;
    margin: 3% 0;
    max-height: 400px;
    height: 100%;
    min-width: fit-content;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.board-member-minimized:hover {
    background-color: rgba(150,150,150,0.2);
}

.bio {
    font-size: 1rem;
}

.board-member-maximized {
    border-radius: 2em;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 5% 5%;
}

.modal-close {
    color: #aaa;
    position: absolute;
    right: 15px;
    top: 0;
    font-size: 34px;
    font-weight: 700;
    cursor: pointer;
    height: 10vmin;
    width: 10vmin;
    transition: color 0.3s;

}

.modal-close:hover {
    color: black;
}

/* Below are styles that impact the modal view itself. Defined by bootstrap.
 * Added !important flag to overwrite bootstrap's default border radius
*/
.modal-content {
    border-radius: 1em !important;
}

.board-member-col {
    margin: 3% 0;
}
