#landing-wrapper {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    background: linear-gradient(#000000, #aa0000, #ffffff);
    align-items: center;
    justify-content: center;
    text-align: center;
}

#main-text {
    font-size: 6rem;
    padding: 0 3%;
    background: -webkit-linear-gradient(#ffffff, #ededed);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

#description, #about-us {
    padding: 0 3%;
}


@media only screen and (max-width: 600px) {
    #main-text {
        font-size: 3rem;
    }
}

@media only screen and (min-width: 601px) and (max-width: 768px) {
    #main-text {
        font-size: 4rem;
    }
}

@media only screen and (min-width: 769px) and (max-width: 992px) {
    #main-text {
        font-size: 4rem;
    }
}
